<template>
  <div id="wave" ref="wave"></div>
</template>
<script>
import { PerspectiveCamera, Scene, BufferGeometry, BufferAttribute, ShaderMaterial, Color, Points, WebGLRenderer} from 'three';
export default {
  name: 'WAVE',
  methods: {
    initWave (dom) {
      // this.container = dom;
      this.SEPARATION = 100;
      this.AMOUNTX = 50;
      this.AMOUNTY = 50;
      this.count = 0;
      this.mouseX = 0;
      this.mouseY = -400;
      this.windowHalfX = window.innerWidth / 2;
      this.windowHalfY = window.innerHeight / 2;

      this.container = document.createElement('div');
      dom.appendChild(this.container);

      this.camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 10000);
      this.camera.position.z = 1000;
      this.scene = new Scene();

      const numParticles = this.AMOUNTX * this.AMOUNTY;
      const positions = new Float32Array(numParticles * 3);
      const scales = new Float32Array(numParticles);
      let i = 0; let j = 0;
      for (let ix = 0; ix < this.AMOUNTX; ix++) {
        for (let iy = 0; iy < this.AMOUNTY; iy++) {
          positions[ i ] = ix * this.SEPARATION - ((this.AMOUNTX * this.SEPARATION) / 2); // x
          positions[ i + 1 ] = 0; // y
          positions[ i + 2 ] = iy * this.SEPARATION - ((this.AMOUNTY * this.SEPARATION) / 2); // z
          scales[ j ] = 1;
          i += 3;
          j++;
        }
      }

      const geometry = new BufferGeometry();
      geometry.setAttribute('position', new BufferAttribute(positions, 3));
      geometry.setAttribute('scale', new BufferAttribute(scales, 1));
      const material = new ShaderMaterial({
        uniforms: {
          color: { value: new Color(0x3c78d8) },
        },
        vertexShader: `
        attribute float scale;
        void main() {
          vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
          gl_PointSize = scale * ( 300.0 / - mvPosition.z );
          gl_Position = projectionMatrix * mvPosition;
        }
        `,
        fragmentShader: `
        uniform vec3 color;
        void main() {
          if ( length( gl_PointCoord - vec2( 0.5, 0.5 ) ) > 0.475 ) discard;
          gl_FragColor = vec4( color, 1.0 );
        }`,
      });

      this.particles = new Points(geometry, material);
      this.scene.add(this.particles);

      this.renderer = new WebGLRenderer({ antialias: true, alpha: true});
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.container.appendChild(this.renderer.domElement);

      this.container.style.touchAction = 'none';
      this.container.addEventListener('pointermove', this.onPointerMove);
      window.addEventListener('resize', this.onWindowResize);
    },
    onWindowResize () {
      this.windowHalfX = window.innerWidth / 2;
      this.windowHalfY = window.innerHeight / 2;
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },
    onPointerMove (event) {
      if (event.isPrimary === false) return;
      this.mouseX = event.clientX - this.windowHalfX;
      this.mouseY = event.clientY - this.windowHalfY;
    },
    renderWave () {
      this.camera.position.x += (this.mouseX - this.camera.position.x) * 0.05;
      // this.camera.position.y += (-this.mouseY - this.camera.position.y) * 0.05;
      this.camera.position.y = 400;
      this.camera.lookAt(this.scene.position);
      const positions = this.particles.geometry.attributes.position.array;
      const scales = this.particles.geometry.attributes.scale.array;

      let i = 0; let j = 0;

      for (let ix = 0; ix < this.AMOUNTX; ix++) {
        for (let iy = 0; iy < this.AMOUNTY; iy++) {
          positions[ i + 1 ] = (Math.sin((ix + this.count) * 0.3) * 50)
										+ (Math.sin((iy + this.count) * 0.5) * 50);

          scales[ j ] = (Math.sin((ix + this.count) * 0.3) + 1) * 8
										+ (Math.sin((iy + this.count) * 0.5) + 1) * 8;

          i += 3;
          j++;
        }
      }

      this.particles.geometry.attributes.position.needsUpdate = true;
      this.particles.geometry.attributes.scale.needsUpdate = true;
      this.renderer.render(this.scene, this.camera);
      this.count += 0.1;
    },
    animate () {
      requestAnimationFrame(this.animate);
      this.renderWave();
    },
  },
  mounted () {
    this.initWave(this.$refs.wave);
    this.animate();
  },
  beforeDestroy () {
    this.container.removeEventListener('pointermove', this.onPointerMove);
    window.removeEventListener('resize', this.onWindowResize);
  },
};
</script>
<style scoped>
#wave {
  background: #203260;
  overflow: hidden;
}
</style>
